import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate, useParams } from "react-router-dom";

const Login = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { rootOrg } = useParams();
  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate(`/${rootOrg}`);
    }
  }, [auth.isAuthenticated, auth.user, navigate, rootOrg]);

  const handleLoginButton = () => {
    const search = window.location.search;

    const pathName = new URLSearchParams(search).get("redirect");

    const redirectTo = new URL(pathName || "", window.location.origin);

    auth.signinRedirect({ redirect_uri: redirectTo.toString() });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-white">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-blue-800 mb-4">IAP</h1>
        <button
          onClick={handleLoginButton}
          className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default Login;
