import React, { ReactElement } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { config } from "./lib/config";

fetch("/config.json")
  .then(async (response) => {
    const data = await response.json();
    const rootOrg = window.location.pathname.split("/")[1];
    config.setConfig = data[rootOrg];
    localStorage.setItem("hostConfig", JSON.stringify(config.getConfig));
    return <App />;
  })
  .catch((e: Error) => {
    return <div className="">{e.message}</div>;
  })
  .then((element: ReactElement) => {
    ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
      <React.StrictMode>{element}</React.StrictMode>
    );
  });

reportWebVitals();
