import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./components/Login";
import { AuthProvider } from "react-oidc-context";
import React from "react";
import { config } from "./lib/config";
import ProtectedRoute from "./components/ProtectedRoute";
import Iframe from "./components/Iframe";

const App = () => {
  return (
    <AuthProvider {...config.getConfig}>
      <React.StrictMode>
        <BrowserRouter>
          <Routes>
            <Route path="/:rootOrg/*">
              <Route path="login" element={<Login />} />
              <Route
                path=":langId/:assessmentType/:assessmentId/*"
                element={
                  <ProtectedRoute>
                    <Iframe />
                  </ProtectedRoute>
                }
              />

              <Route
                path="*"
                element={
                  <div className="min-h-screen flex items-center justify-center bg-white">
                  <div
                    className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4"
                    role="alert"
                  >
                    <p className="font-bold">Page Not Found Error </p>
                    <p>The page you requested cannot be found.</p>
                  </div>
                  </div>
                }
              />
            </Route>
            <Route
              path="*"
              element={
                <div className="min-h-screen flex items-center justify-center bg-white">
                <div
                  className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4"
                  role="alert"
                >
                  <p className="font-bold">Page Not Found Error</p>
                  <p>Contest Url is missing RootOrg</p>
                </div>
                </div>
              }
            />
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    </AuthProvider>
  );
};

export default App;
