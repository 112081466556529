import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";

type JWTDeCode = {
  wid: string;
};
const Iframe = () => {
  const auth = useAuth();
  const decoded: JWTDeCode = jwt_decode(auth.user?.access_token!);
  console.log(decoded)
  const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === "IFRAME";

    const { rootOrg, assessmentType, assessmentId, langId } = useParams();
  useEffect(() => {
    const lexResponseHandler = (event: MessageEvent) => {

      if (event.origin.indexOf("iap.infosysapps.com") <= -1) {
        if (event.origin.indexOf("lex-iap.infosysapps.com") <= -1)
        if (event.origin.indexOf("lex-deviap.infosysapps.com") <= -1)
        if(event.origin.indexOf("proctor-dev.iap.infosysapps.com") <= -1) 
        if(event.origin.indexOf("proctor.iap.infosysapps.com") <= -1){
          if (event.origin.indexOf("localhost") <= -1) {
            if (event.origin.indexOf("localhost:3000") <= -1) {
              console.log("origin NOT accepted");
              //return
            }
          }
        }
      }

      if (!event.data) {
        console.log("data not available");
        return;
      }

      if (event.data.type && event.data.type === "ELECTRON_REQUEST") {
        let electronData = {
          type: "ELECTRON_RESPONSE",
          electronApi: true,
        };
        let win = document.getElementById("iFrameForProctor")!;
        if (isIFrame(win) && win.contentWindow) {
          win.contentWindow.postMessage(electronData, "*");
        }
      }
      if (event.data.type && event.data.type === "AUTH_REQUEST") {
        let dataToSend = {
          app: "LEX_APP",
          type: "AUTH_RESPONSE",
          state: "NONE",
          plugin: "NONE",
          data: {
            id: event.data.data.id,
            token: `${auth.user?.access_token}`,
          },
        };
        let themeToSend = {
          app: "WEB_PORTAL",
          type: "THEME_RESPONSE",
          state: "NONE",
          plugin: "NONE",
          parentContext: {
            rootOrg: rootOrg,
            domainName: "https://lex-deviap.infosysapps.com",
            theme: {
              name: "Blue Theme",
            },
            url: `http://lex-deviap.infosysapps.com/${assessmentType}/${assessmentId}`,
            user: {
              roles: ["User"],
              userId: decoded.wid,
            },
            darkMode: false,
          },
        };
        let win = document.getElementById("iFrameForProctor")!;
        if (isIFrame(win) && win.contentWindow) {
          win.contentWindow.postMessage(dataToSend, "*");
          win.contentWindow.postMessage(themeToSend, "*");
        }
      }
    };
    window.addEventListener("message", lexResponseHandler);
    return () => {
      window.removeEventListener("message", lexResponseHandler);
    };
    
  }, [assessmentId, assessmentType, auth.user?.access_token, decoded.wid, rootOrg]);
  return (
    <div className="App">
      <iframe
        src={`https://lex-deviap.infosysapps.com/${langId}/${assessmentType}/${assessmentId}`}
        id="iFrameForProctor"
        title="iFrameForProctor"
        width={"100%"} height={"640"}
        allowFullScreen = {true}
        allow="autoplay *; fullscreen *; encrypted-media *; microphone; camera; display-capture *;"
      ></iframe>
    </div>
  );
};

export default Iframe;
