import { TConfig } from "../components/models/config/config";


class Config {
    private _config: TConfig = {};
    private _isDefined = false;

    public get getConfig(): TConfig {
        if (this._isDefined) {
            return this._config;
        }

        throw new Error('Host Config not defined');
    }

    public set setConfig(value: TConfig) {
        if (this._isDefined) {
            throw new Error('Host Config already defined');
        }

        this._config = value;
        this._isDefined = true;
    }

    
}

export const config = new Config();

export const configFileName = 'config.json';